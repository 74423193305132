import { useEffect, useState } from 'react';
import './App.css';
const base = require('./dump-base.json')
const lag = require('./dump_LAG6.json')


function App() {
  const dataLen = base.length
  const [remQ, setRemQ] = useState([...Array(dataLen*2).keys()])
  const [current, setCurrent] = useState(0)
  const [displayElement, setDisplayElement] = useState(<div></div>)

  const [currentBase, setCurrentBase] = useState(Math.floor(Math.random() * 2))
  const [score, setScore] = useState([0, 0])

  useEffect(() => {
    if (localStorage.getItem("remQ") === null) {
      let start = remQ[Math.floor(Math.random() * remQ.length)]
      setCurrent(start)
      setRemQ((prev) => prev.filter((i) => i != start))
    } else {
      if (localStorage.getItem("current") != "null")
        setCurrent(parseInt(localStorage.getItem("current")))
      else
        setCurrent(null)
      setRemQ(JSON.parse("[" + localStorage.getItem("remQ") + "]"))
      setScore(JSON.parse("[" + localStorage.getItem("score") + "]"))
    }
  }, [])

  function handleClick(target) {
    if (target === currentBase) setScore((prev) => [prev[0], prev[1] + 1])
    else setScore((prev) => [prev[0] + 1, prev[1]])
    
    handleNext();
  }

  useEffect(() => {
    localStorage.setItem("score", score)
    localStorage.setItem("current", current)
    localStorage.setItem("remQ", remQ)
  }, [score, current, remQ])

  function handleNext() {
    setRemQ((prev) => {
      if (prev.length > 0) {
        const next = prev[Math.floor(Math.random() * prev.length)]
        setCurrent(next)
        return prev.filter((i) => i != next)
      } else {
        setCurrent(null)
        return []
      }
    })
    
    setCurrentBase(Math.floor(Math.random() * 2))
  }

  function handleReset() {
    setScore([0,0])
    const newRemQ = [...Array(dataLen*2).keys()]
    let start = newRemQ[Math.floor(Math.random() * newRemQ.length)]
    setCurrent(start)
    setRemQ(newRemQ.filter((i) => i != start))
  }

  useEffect(() => {
    if (current === null) {
      setDisplayElement(
        <div className='mainApp'>
          <div className='instruction'>
            <div className='bold'>Scores</div>
            <div className='score'>
              <div>LoRA-AG 6</div>
              <div>{score[0]}</div>
            </div>
            <div className='score'>
              <div>Base Mistral</div>
              <div>{score[1]}</div>
            </div>
            <div className='h-5'></div>
          </div>

          You have completed the survey.
          Please screenshot this page and send it to our team for data collection.
          We thank you kindly for your contribution!
          Your support pushes us to innovate and keep going.
          <div className='h-6'></div>
          <div className='reset' onClick={() => {handleReset()}}>Reset</div>
        </div>
      )
      return
    }

    let aData;
    let bData;
    if (currentBase === 0){
      aData = base;
      bData = lag;
    } else {
      aData = lag;
      bData = base;
    }
    setDisplayElement(
      <div className='mainApp'>
        <div className='instruction'>
          <div className='bold'>Please select your preferred evaluated response!<br />
          (response order is shuffled)
          </div>
        </div>

        <div className='instruction'>
          <div className='bold'>Scores</div>
          <div className='score'>
            <div>LoRA-AG 6</div>
            <div>{score[0]}</div>
          </div>
          <div className='score'>
            <div>Base Mistral</div>
            <div>{score[1]}</div>
          </div>
          <div className='h-5'></div>
        </div>

        <div className='choiceCard prompt'>
          
          {current >= dataLen ? 
            <div>
              <div className='responseName'>Previous Prompt</div>
              {base[Math.floor(current/2)]['in1'] }
              <div className='h-4'></div>
              <div className='responseName'>Current Prompt</div>
              {base[Math.floor(current/2)]['in2'] }
            </div> : 
            <div>
              <div className='responseName'>Current Prompt</div>
              {base[current]['in1'] }
            </div>
          }
        </div>
        
        <div className='choicesContainer'>
          
          <div 
            className='choiceCard selectable'
            onClick={() => {handleClick(0)}}
          >
            {current >= dataLen ?
              <div>
                <div className='responseName'>Previous Response A</div>
                {aData[Math.floor(current/2)]['out1']}
                <div className='h-5'></div>
                <div className='responseName'>Evaluated Response A</div>
                {aData[Math.floor(current/2)]['out2']}
              </div>
              :
              <div>
                <div className='responseName'>Evaluated Response A</div>
                {aData[current]['out1']}
              </div>
            }
          </div>
          <div 
            className='choiceCard selectable'
            onClick={() => {handleClick(1)}}
          >
            {current >= dataLen ?
              <div>
                <div className='responseName'>Previous Response B</div>
                {bData[Math.floor(current/2)]['out1']}
                <div className='h-5'></div>
                <div className='responseName'>Evaluated Response B</div>
                {bData[Math.floor(current/2)]['out2']}
              </div>
              :
              <div>
                <div className='responseName'>Evaluated Response B</div>
                {bData[current]['out1']}
              </div>
            }
          </div>
        </div>
        <div className='h-5'></div>
        <div style={{"display":"flex", "gap": "1em"}}>
          <div className='reset' onClick={() => {handleReset()}}>Reset</div>
          <div className='skip' onClick={() => {handleNext()}}>Skip Round</div>
        </div>
      </div>
    )
  }, [current])

  return (
    <div className="App">
      {displayElement}
    </div>
  );
}

export default App;
